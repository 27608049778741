import { UserStore } from '~/store/userStore'
import { AppMessageConsumer } from '~/lib/app/appState'
import { showError } from '~/store/notificationStore'

const MaxFailures = 10
const PollIntervalMs = 20_000
const JitterRangeMs = 5_000

export function startAndStopUserDataPolling(
  userStore: UserStore
): AppMessageConsumer<'auth-state-changed'> {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  // we only want to log polling failure once to reduce spam
  let didShowError = false

  // we will stop retrying polling when we reach a certain amount of failures
  let failureCounter = 0

  function startPolling() {
    timeoutId = setTimeout(async function pollUserData() {
      try {
        await userStore.refreshData()

        if (failureCounter > 0) {
          console.log('successfully restarted polling user data')

          // Reset the failure counter on a successful load
          failureCounter = 0
        }
      } catch (e) {
        console.error(e)
        if (!didShowError) {
          didShowError = true
          showError(`Error polling user: ${e}`)
        }

        failureCounter += 1
        if (failureCounter >= MaxFailures) {
          // probably some catastrophic failure, so give up (probably just running in dev mode)
          console.error(`Failed to poll user data ${failureCounter} times. Giving up `)
          stopPolling()
          return
        }
      }

      setTimeout(pollUserData, addJitter(PollIntervalMs, JitterRangeMs))
    })
  }

  function stopPolling() {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }

  return ({ user }) => {
    stopPolling()

    didShowError = false
    failureCounter = 0

    if (user != null) {
      console.log('polling started')
      // user is logged in so we do want to begin polling
      startPolling()
    } else {
      console.log('polling stopped')
    }
  }
}

function addJitter(baseTimeout: number, jitterRange: number): number {
  // Calculate a random jitter within the specified range
  const jitter = Math.random() * jitterRange - jitterRange / 2

  // Add the jitter to the base timeout
  return baseTimeout + jitter
}
