import { Temporal, toTemporalInstant } from '@js-temporal/polyfill'

declare global {
  export interface Date {
    toTemporalInstant(): Temporal.Instant
  }
}

export default function () {
  // @ts-ignore
  Date.prototype.toTemporalInstant = toTemporalInstant
}
