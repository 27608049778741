import { ref, Ref, useContext } from '@nuxtjs/composition-api'
import Vue from 'vue'

interface DemoStoreState {
  isWorking: boolean
}

class DemoStore {
  constructor(
    private readonly state: Ref<DemoStoreState>,
    private readonly ctx: ReturnType<typeof useContext>
  ) {}

  private get $user() {
    return this.ctx.$app.user
  }

  private get $fetcher() {
    return this.ctx.$app.fetcher
  }

  async startDemo() {
    if (this.state.value.isWorking) return

    Vue.set(this.state.value, 'isWorking', true)

    try {
      const demoAccountId = 'd00de499-8510-4ad8-829d-abee5eb80fa7'

      await this.$fetcher.$post('/api/admin/demo/reset', {})
      await this.$user.impersonate({ companyGuid: demoAccountId })
    } finally {
      Vue.set(this.state.value, 'isWorking', false)
    }
  }

  async stopDemo() {
    await this.$user.unimpersonate()
  }
}

export function useDemoStore() {
  const ctx = useContext()
  return new DemoStore(ref({ isWorking: false }), ctx)
}
