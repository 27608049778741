import mixpanel, {
  Callback,
  Dict,
  OverridedMixpanel,
  People,
  RegisterOptions,
  RequestOptions,
} from 'mixpanel-browser'

export interface Analytics {
  track(
    // TODO limit this to just AnalyticsEvent
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ): void
  register(props: Dict, days_or_options?: number | Partial<RegisterOptions>): void
  people: People
  track_pageview(properties?: Dict, options?: { event_name?: string | undefined }): void
}

export enum AnalyticsEvent {
  TalentSubmittedSignupKickoutQuestions = 'Talent Submitted Signup Kickout Questions',
}

export async function initializeAnalytics(mixpanelId: string, debug: boolean): Promise<Analytics> {
  await new Promise((resolve) => {
    mixpanel.init(mixpanelId, {
      debug,
      persistence: 'localStorage',
      loaded: () => resolve(undefined),
    })
  })

  return mixpanel
}
