import { onMounted, onUnmounted, readonly, ref } from '@nuxtjs/composition-api'

// https://developers.google.com/youtube/iframe_api_reference#Examples
export interface YoutubePlayer {
  new (
    elementId: string,
    props: {
      events: YoutubePlayerEvents
    }
  ): YoutubePlayer

  playVideo(): void

  stopVideo(): void

  destroy(): void

  getPlayerState(): number

  getCurrentTime(): number

  getDuration(): number
}

export interface YoutubePlayerEvents {
  onReady?: (e: YoutubePlayerEvent<null>) => void
  onStateChange?: (e: YoutubePlayerEvent<number>) => void
}

export interface YoutubePlayerEvent<Data> {
  data: Data
  target: YoutubePlayer
}

export interface YoutubePlayerStateEnum {
  ENDED: 0
  PLAYING: 1
  PAUSED: 2
  BUFFERING: 3
  CUED: 5
}

export function useYoutubeIframeAPI(iframeElementId: string, events: YoutubePlayerEvents) {
  let intervalHandle: ReturnType<typeof setInterval>

  const currentTime = ref<number>()
  const duration = ref<number>()

  function startTimerUpdate() {
    intervalHandle = setInterval(function () {
      currentTime.value = player.getCurrentTime()
    }, 100)
  }

  function stopTimerUpdate() {
    clearInterval(intervalHandle)
  }

  function onReady(e: YoutubePlayerEvent<null>) {
    events.onReady?.(e)
    duration.value = e.target.getDuration()
  }

  function onStateChange(e: YoutubePlayerEvent<number>) {
    if (e.data === window.YT!.PlayerState.PLAYING) {
      startTimerUpdate()
    } else if (e.data === window.YT!.PlayerState.PAUSED) {
      stopTimerUpdate()
    }

    events.onStateChange?.(e)
  }

  let player: YoutubePlayer
  onMounted(async () => {
    await loadYoutubeIframeAPI()

    player = new window.YT!.Player(iframeElementId, {
      events: {
        onStateChange,
        onReady,
      },
    })
  })

  onUnmounted(() => {
    player.destroy()
    stopTimerUpdate()
  })

  return {
    currentTime: readonly(currentTime),
    duration: readonly(duration),
  }
}

export function loadYoutubeIframeAPI() {
  return new Promise((resolve) => {
    if (!!window.YT) {
      return resolve(undefined)
    }

    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'

    const firstScriptTag = document.getElementsByTagName('script')[0]!
    firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag)

    // @ts-ignore
    window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
      resolve(undefined)
    }
  })
}
