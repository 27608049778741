import {
  Auth as FirebaseAuth,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  useDeviceLanguage,
  User as FirebaseUser,
  UserCredential,
  connectAuthEmulator,
} from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { AppMessageBus } from '~/lib/app/appState'

export interface CurrentUser {
  readonly uid: string
  readonly email: string | null

  /**
   * load the JWT used for backend auth
   */
  getIdToken(): Promise<string | null>
}

export interface AuthService {
  /**
   * @deprecated use `getUser`
   * the currently logged in user
   */
  currentUser: CurrentUser | null

  isLoggedIn: boolean

  getUser(): Promise<CurrentUser | null>

  /**
   * load the JWT used for backend auth
   */
  getIdToken(): Promise<string | null>

  sendPasswordResetEmail(email: string): Promise<void>

  login(email: string, password: string): Promise<void>

  logout(): Promise<void>

  createUserCredentials(email: string, password: string): Promise<UserCredential>

  /**
   * delete the firebase account of the current user
   * might require a call to `reauthenticateCurrentUser first
   */
  deleteCurrentUser(): Promise<void>

  reauthenticateCurrentUser(password: string): Promise<void>

  /**
   * Check if an email is available to be used for signup
   */
  checkEmailAvailability(email: string): Promise<boolean>

  /**
   * change the password of the current user
   * might require a call to `reauthenticateCurrentUser first
   */
  changePasswordForCurrentUser(password: string): Promise<void>
}

export class FirebaseAuthService implements AuthService {
  private constructor(
    private readonly auth: FirebaseAuth,
    private readonly messageBus: AppMessageBus
  ) {}

  get currentUser(): CurrentUser | null {
    return this.auth.currentUser ?? null
  }

  get isLoggedIn() {
    return this.currentUser != null
  }

  static init(messageBus: AppMessageBus, emulatorUrl: string | undefined): FirebaseAuthService {
    const fire = initializeApp({
      apiKey: 'AIzaSyA5BchY7OTxiTtj7r4mHXp3E6EsVLPGmNU',
      authDomain: 'hyre-766f2.firebaseapp.com',
      databaseURL: 'https://hyre-766f2.firebaseio.com',
      projectId: 'hyre-766f2',
      storageBucket: 'hyre-766f2.appspot.com',
      messagingSenderId: '522227306262',
      appId: '1:522227306262:web:201624883653cf5dcf0a37',
      measurementId: 'G-KWRWBBCKRS',
    })

    const auth = getAuth(fire)

    if (emulatorUrl) {
      connectAuthEmulator(auth, emulatorUrl)
    }

    useDeviceLanguage(auth)

    onAuthStateChanged(auth, (user) => {
      messageBus.publish('auth-state-changed', { user })
    })

    return new FirebaseAuthService(auth, messageBus)
  }

  // We only need this for the weird stuff we were doing with getting the bearer token for axios/fetcher
  async getUser(): Promise<CurrentUser | null> {
    return await this.getFirebaseUser()
  }

  // But we probably actually only need to access auth.currentUser.getIdToken(), idk this is something to look into
  async getIdToken(): Promise<string | null> {
    const user = await this.getUser()

    return user?.getIdToken() ?? null
  }

  async login(email: string, password: string) {
    await signInWithEmailAndPassword(this.auth, email, password)
    this.messageBus.publish('user-logged-in', {})
  }

  async logout() {
    await this.auth.signOut()
    this.messageBus.publish('user-logged-out', {})
  }

  async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(this.auth, email)
  }

  async checkEmailAvailability(email: string): Promise<boolean> {
    const methods = await fetchSignInMethodsForEmail(this.auth, email)
    return methods.length === 0
  }

  // TODO don't leak firebase.auth.UserCredential
  async createUserCredentials(email: string, password: string): Promise<UserCredential> {
    return await createUserWithEmailAndPassword(this.auth, email, password)
  }

  async reauthenticateCurrentUser(password: string) {
    const user = await this.getFirebaseUser()
    if (!user) throw new Error('not logged in')
    if (!user.email) throw new Error('user has no email configured')

    const credential = EmailAuthProvider.credential(user.email, password)
    await reauthenticateWithCredential(user, credential)
  }

  async deleteCurrentUser(): Promise<void> {
    await this.auth.currentUser?.delete()
  }

  async changePasswordForCurrentUser(password: string): Promise<void> {
    const user = await this.getFirebaseUser()
    if (!user) throw new Error('not logged in')

    await updatePassword(user, password)
  }

  private async getFirebaseUser(): Promise<FirebaseUser | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(this.auth, (user) => {
        resolve(user)
        unsubscribe()
      })
    })
  }
}
