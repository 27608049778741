import { computed, useRoute, useRouter, WritableComputedRef } from '@nuxtjs/composition-api'

/**
 * get a computed ref which reads and writes to a query param
 * !!WARNING!! there is a little bug in here -
 * due to the update being asynchronous, if you write to it and then read from
 * it directly afterwards, you will receive the old value. Not a problem atm
 * something to consider in the future
 *
 * @param param
 * @param defaultValue
 */
export function useSearchParam(param: string) {
  const route = useRoute()
  const router = useRouter()

  return computed<string | Array<string | null> | undefined>({
    get: () => route.value.query[param],
    set: (v) => {
      // do it in next task, so we don't overwrite previous updates to other search params
      setTimeout(() =>
        // No need to make it all end up in browser history
        router.replace({
          path: route.value.path,
          query: Object.assign({}, route.value.query, { [param]: v }),
        })
      )
    },
  })
}

export function useStringSearchParameter(param: string): WritableComputedRef<string | undefined>

export function useStringSearchParameter(
  param: string,
  defaultValue: string
): WritableComputedRef<string>

/**
 * get a computed ref which reads and writes to a query param
 * !!WARNING!! there is a little bug in here -
 * due to the update being asynchronous, if you write to it and then read from
 * it directly afterwards, you will receive the old value. Not a problem atm
 * something to consider in the future
 *
 * @param param
 * @param defaultValue
 */
export function useStringSearchParameter(param: string, defaultValue?: string) {
  const searchParam = useSearchParam(param)
  return computed<string | undefined>({
    get: () => {
      if (searchParam.value instanceof Array) {
        return searchParam.value[0] ?? defaultValue
      } else {
        return searchParam.value ?? defaultValue
      }
    },
    set: (v) => {
      searchParam.value = v
    },
  })
}

export function useArraySearchParameter<E extends string = string>(
  param: string
): WritableComputedRef<Array<E | null> | undefined>

export function useArraySearchParameter(
  param: string,
  defaultValue: Array<string>
): WritableComputedRef<Array<string | null>>

export function useArraySearchParameter<E extends string = string>(
  param: string,
  defaultValue: Array<E>
): WritableComputedRef<Array<E | null>>

export function useArraySearchParameter(
  param: string,
  defaultValue?: Array<string | null>
): WritableComputedRef<Array<string | null> | undefined> {
  const searchParam = useSearchParam(param)
  return computed({
    get: () => {
      const paramValue = searchParam.value
      if (paramValue == null) return defaultValue
      else if (paramValue instanceof Array) return paramValue
      else return [paramValue]
    },
    set: (v) => {
      searchParam.value = v
    },
  })
}

export function useBooleanSearchParameter(param: string): WritableComputedRef<boolean | undefined> {
  const stringParameter = useStringSearchParameter(param)
  return computed({
    get: () => stringParameter.value == 'true',
    set: (v) => (stringParameter.value = v ? 'true' : undefined),
  })
}

export function useEnumSearchParameter<E extends string>(
  param: string,
  enumValues: ReadonlyArray<E>
): WritableComputedRef<E | undefined>

export function useEnumSearchParameter<E extends string>(
  param: string,
  enumValues: ReadonlyArray<E>,
  defaultValue: E
): WritableComputedRef<E>

export function useEnumSearchParameter<E extends string>(
  param: string,
  enumValues: ReadonlyArray<E>,
  defaultValue?: E
): WritableComputedRef<E | undefined> {
  const stringParameter = useStringSearchParameter(param)
  return computed({
    get: () =>
      stringParameter.value != undefined && enumValues.includes(stringParameter.value as E)
        ? (stringParameter.value as E)
        : defaultValue,
    set: (v: E | undefined) => (stringParameter.value = v ?? defaultValue),
  })
}

export function useNumberSearchParameter(param: string): WritableComputedRef<number | undefined>

export function useNumberSearchParameter(
  param: string,
  defaultValue: number
): WritableComputedRef<number>

export function useNumberSearchParameter(
  param: string,
  defaultValue?: number
): WritableComputedRef<number | undefined> {
  const stringParameter = useStringSearchParameter(param)

  return computed({
    get: () => {
      if (!stringParameter.value) return defaultValue

      const number = Number(stringParameter.value)

      if (Number.isNaN(number)) return defaultValue

      return number
    },
    set: (v) => {
      if (v == undefined && defaultValue == undefined) {
        stringParameter.value = undefined
      } else {
        stringParameter.value = String(v ?? defaultValue)
      }
    },
  })
}
