import { MessageBus, MessageBusImpl, MessageConsumer } from '~/lib/app/messageBus/messageBus'
import { UserStore } from '~/store/userStore'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { Fetcher, FetcherImpl } from '~/lib/app/fetcher'
import { LangService } from '~/lib/app/langService'
import { NotificationStore } from '~/store/notificationStore'
import { HyrePublicConfig } from '~/config/hyrePublicConfig'
import { AuthService, FirebaseAuthService } from '~/lib/app/auth'
import { Analytics, initializeAnalytics } from '~/lib/app/analytics'
import { AppMessages } from '~/lib/app/appMessages'
import { createScreeningTaskRepo, ScreeningTaskRepo } from '~/lib/app/screeningTaskRepo'
import { TalentService } from '~/lib/app/talentService'
import { MetaConversionsTracker } from '~/lib/app/metaConversions'
import { AppDebug } from '~/lib/app/AppDebug'

export class AppState {
  private constructor(
    private readonly _messageBus: MessageBusImpl<AppMessages>,
    public readonly user: UserStore,
    public readonly analytics: Analytics,
    public readonly appInsights: ApplicationInsights,
    private readonly _fetcher: FetcherImpl,
    public readonly _auth: FirebaseAuthService,
    public readonly lang: LangService,
    public readonly notif: NotificationStore,
    public readonly talentService: TalentService,
    public readonly screeningTasks: ScreeningTaskRepo,
    public readonly meta: MetaConversionsTracker
  ) {}

  // do not use
  private readonly appDebug = new AppDebug()

  public get messageBus(): AppMessageBus {
    return this._messageBus
  }

  public get fetcher(): Fetcher {
    return this._fetcher
  }

  public get auth(): AuthService {
    return this._auth
  }

  static async init(
    cfg: HyrePublicConfig,
    setLocale: (locale: string) => void,
    configureMessageConsumers: (app: AppState, messageBus: MessageBusImpl<AppMessages>) => void,
    configureFetcher: (app: AppState, fetcher: FetcherImpl) => void
  ): Promise<AppState> {
    const messageBus = new MessageBusImpl<AppMessages>()

    const analytics = await initializeAnalytics(cfg.mixpanelProjectId, !cfg.isProduction)

    const appInsights = initializeAppInsights(cfg.appInsightsConnectionString)

    const fetcher = new FetcherImpl(cfg.apiUrl)

    const auth = FirebaseAuthService.init(messageBus, cfg.firebaseEmulatorUrl)

    const user = UserStore.init(auth, fetcher, messageBus)

    const lang = LangService.init(setLocale, messageBus)

    const notif = NotificationStore.init()

    const talentService = new TalentService(auth, fetcher, user, messageBus)

    const meta = MetaConversionsTracker.init(cfg.metaPixelId, fetcher, user)

    const screeningTasksRepo = createScreeningTaskRepo(fetcher)

    const app = new AppState(
      messageBus,
      user,
      analytics,
      appInsights,
      fetcher,
      auth,
      lang,
      notif,
      talentService,
      screeningTasksRepo,
      meta
    )

    configureMessageConsumers(app, app._messageBus)
    configureFetcher(app, app._fetcher)

    return app
  }
}

export type AppMessageBus = MessageBus<AppMessages>

export type AppMessageConsumer<MessageId extends keyof AppMessages> = MessageConsumer<
  AppMessages[MessageId]
>

function initializeAppInsights(connectionString: string): ApplicationInsights {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      cookieCfg: { enabled: false },
    },
  })

  appInsights.loadAppInsights()

  return appInsights
}
