import { Ref, watch } from '@nuxtjs/composition-api'
import { useLocalStorage } from '@vueuse/core'
import { AppMessageBus } from '~/lib/app/appState'

export class LangService {
  constructor(private readonly _code: Ref<string>) {}
  get code() {
    return this._code.value
  }

  set code(code: string) {
    if (code !== 'en' && code !== 'de') {
      throw new Error('Invalid locale code')
    }
    this._code.value = code
  }

  static init(setLocale: (locale: string) => void, messageBus: AppMessageBus) {
    const lang = new LangService(useLocalStorage('language', 'de'))

    // TODO we will only enable language switching for recruiters
    //      and they can only do so from the settings
    // when the browser language changes automatically update the language
    // const navigatorLanguage = useNavigatorLanguage()
    // watch(
    //   () => navigatorLanguage.language.value,
    //   (value) => {
    //     const [l] = value?.split('-') ?? ['de']
    //     lang.code = l
    //   }
    // )

    watch(
      () => lang.code,
      (value) => {
        setLocale(value)
        messageBus.publish('language-changed', { lang: value })
      },
      { immediate: true }
    )

    return lang
  }
}
