import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=7c4d05cd"
import script from "./AppHeader.vue?vue&type=script&lang=ts&setup=true"
export * from "./AppHeader.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLogo: require('/github/workspace/components/BaseLogo.vue').default,HotlistStatusSelectorPopupToggleButtonMobile: require('/github/workspace/components/HotlistStatusSelectorPopupToggleButtonMobile.vue').default,SvgHamburger: require('/github/workspace/components/SvgHamburger.vue').default,SvgCross: require('/github/workspace/components/SvgCross.vue').default})
