import { ref, useContext } from '@nuxtjs/composition-api'
import { components } from '~/types/generated/schema'
import Vue from 'vue'
import { showError } from '~/store/notificationStore'
import { UserStore, useUserStore } from '~/store/userStore'
import { useFetcher } from '~/store/useFetcher'
import { Fetcher } from '~/lib/app/fetcher'

export type TalentProfile = components['schemas']['TalentProfileDto']

/**
 *  TODO talentGuid is in the public api of our repo but it is unused because the server returns talents based on auth
 *       it probably makes sense to update the endpoint to take talentGuid
 */
export class TalentProfileStore {
  constructor(private readonly fetcher: Fetcher, private readonly user: UserStore) {}

  private _profile = ref<TalentProfile>()

  get profile() {
    return this._profile.value ?? {}
  }

  set profile(v: TalentProfile) {
    this._profile.value = v
  }

  async load() {
    this._profile.value = await this.fetcher.$get(`/api/v2/talent/profile`)
  }

  async update() {
    if (!this._profile.value) {
      throw new Error('Profile was not loaded first!')
    }

    await this.fetcher.$post(`/api/v2/talent/profile`, this._profile.value)
  }

  async uploadTalentDocument(file: File) {
    const formData = new FormData()
    formData.append('file', file)
    const uploadedFile = await this.fetcher.$post(
      `/api/talent/${this.user.externalUserId}/documents` as `/api/talent/{externalUserId}/documents`,
      formData
    )

    Vue.set(this._profile.value!, 'documents', [
      ...(this._profile.value!.documents ?? []),
      uploadedFile,
    ])
  }

  async deleteTalentDocument(id: string) {
    await this.fetcher.$delete(
      `/api/talent/${this.user.externalUserId}/documents/${id}` as `/api/talent/{externalUserId}/documents/{fileId}`
    )

    Vue.set(
      this._profile.value!,
      'documents',
      this._profile.value!.documents?.filter((doc) => doc.externalId !== id) ?? []
    )
  }
}

export function useTalentProfileStore() {
  const fetcher = useFetcher()
  const user = useUserStore()
  return new TalentProfileStore(fetcher, user)
}
