export interface MessageBus<Messages extends {}> {
  publish<MessageId extends keyof Messages>(
    messageId: MessageId,
    messageContent: Messages[MessageId]
  ): void
}

export interface MessageBusInit<Messages extends {}> {
  addConsumer<MessageId extends keyof Messages>(
    messageId: MessageId,
    consumer: MessageConsumer<Messages[MessageId]>
  ): void
}

export interface MessageConsumer<Message> {
  (messageContent: Message): void | Promise<void>
}

/**
 * An MessageBus which can also accept consumers to be used during init
 */
export class MessageBusImpl<Messages extends {}>
  implements MessageBus<Messages>, MessageBusInit<Messages>
{
  private readonly target = document.createElement('message-bus-target')

  constructor() {}

  addConsumer<MessageId extends keyof Messages>(
    messageId: MessageId,
    consumer: MessageConsumer<Messages[MessageId]>
  ): void {
    this.target.addEventListener(messageId.toString(), (evt: Event) => {
      const customEvent = evt as CustomEvent<Messages[MessageId]>
      consumer(customEvent.detail)
    })
  }

  publish<MessageId extends keyof Messages>(
    messageId: MessageId,
    messageContent: Messages[MessageId]
  ): void {
    this.target.dispatchEvent(new CustomEvent(messageId.toString(), { detail: messageContent }))
  }
}
