import { Fetcher } from '~/lib/app/fetcher'
import { initPixel } from '~/lib/app/initPixel'
import { Temporal } from '@js-temporal/polyfill'
import { UserStore } from '~/store/userStore'
import { makeHash } from '~/lib/utils/makeHash'
import { getCookie } from '~/lib/utils/getCookie'

/**
 * The possible standard event types to use with fbq('track')
 * see: https://developers.facebook.com/docs/meta-pixel/reference#standard-events
 * We can just add them in as we need them since we won't need them all
 * They also have specific object properties, but we don't worry about these for now
 */
type MetaStandardEvent = 'CompleteRegistration'

export enum MetaCustomEvent {
  ProfessionalSignup = 'Talents choosing type of sales experience Professional in new signup flow',
}

export interface Fbq {
  (action: 'init', pixelId: string): void

  (action: 'consent', consent: 'revoke' | 'grant'): void

  (
    action: 'track',
    event: MetaStandardEvent,
    eventData: unknown,
    eventId: { eventID: string }
  ): void

  (
    action: 'trackCustom',
    event: MetaCustomEvent,
    eventData: unknown,
    eventId: { eventID: string }
  ): void
}

/**
 * wrapper of the meta pixel + conversions API
 * see: https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
 */
export class MetaConversionsTracker {
  private constructor(
    private readonly pixelId: string,
    private readonly fbq: Fbq,
    private readonly fetcher: Fetcher,
    private readonly user: UserStore
  ) {}

  static init(pixelId: string, fetcher: Fetcher, user: UserStore) {
    const fbq = initPixel()

    // revoke consent before init, grant it later when cookie consent is given
    // see: https://developers.facebook.com/docs/meta-pixel/implementation/gdpr
    fbq('consent', 'revoke')
    fbq('init', pixelId)

    return new MetaConversionsTracker(pixelId, fbq, fetcher, user)
  }

  /**
   * Track a meta standard event
   */
  async track(event: MetaCustomEvent) {
    const eventId = this.generateRandomEventId()

    const now = Temporal.Now.instant()

    this.fbq('trackCustom', event, {}, { eventID: eventId })

    const fbc = getCookie('_fbc')
    const fbp = getCookie('_fbp')

    if (!fbc || !fbp) {
      console.debug('missing fbp or fbc')
      return
    }

    // see: https://developers.facebook.com/docs/marketing-api/conversions-api/using-the-api
    await this.fetcher.$post('/_wrappers/meta-conversions/track', {
      eventName: event,
      eventTime: now.epochSeconds!,
      eventId: eventId!,
      eventSourceUrl: location.toString()!,
      actionSource: 'website',
      fbc,
      fbp,
      pixelId: String(this.pixelId),
    })
  }

  updateConsent(consent: boolean) {
    this.fbq('consent', consent ? 'grant' : 'revoke')
  }

  private generateRandomEventId(): string {
    return crypto.randomUUID()
  }
}
