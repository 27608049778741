import { delay } from '~/lib/utils/delay'

export interface RetryConfig {
  label?: string

  /**
   * The starting number of milliseconds to wait to retry.
   * this will increase each time
   */
  baseMs?: number

  /**
   * The maximum number of times to retry
   */
  maxRetries?: number
}

/**
 * Keep calling fn until it succeeds with exponential fallback
 * If there is still an error after max retries, then that error is thrown
 */
export async function doWithRetry<T>(
  fn: () => Promise<T>,
  cfg?: RetryConfig | number | undefined
): Promise<T> {
  const defaultMs = 2000

  let config: Required<RetryConfig>

  if (!cfg) {
    config = {
      label: 'request',
      baseMs: defaultMs,
      maxRetries: 5,
    }
  } else if (typeof cfg === 'number') {
    config = {
      label: 'request',
      baseMs: defaultMs,
      maxRetries: cfg,
    }
  } else {
    config = {
      label: cfg?.label ?? 'request',
      baseMs: cfg?.baseMs ?? defaultMs,
      maxRetries: cfg?.maxRetries ?? 5,
    }
  }

  return doWithRetryCore(fn, config, 0)
}

async function doWithRetryCore<T>(
  fn: () => Promise<T>,
  config: Required<RetryConfig>,
  currentRetryCount: number
): Promise<T> {
  try {
    return await fn()
  } catch (e) {
    if (currentRetryCount >= config.maxRetries) {
      throw e
    } else {
      const waitMs = config.baseMs * Math.pow(2, currentRetryCount)
      console.debug(`retrying ${config.label} in ${waitMs}ms`)
      await delay(waitMs)
      return doWithRetryCore(fn, config, currentRetryCount + 1)
    }
  }
}
