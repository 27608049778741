import { render, staticRenderFns } from "./FeedbackModal.vue?vue&type=template&id=508a5576"
import script from "./FeedbackModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./FeedbackModal.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBgCover: require('/github/workspace/components/BaseBgCover.vue').default,FeedbackModalContent: require('/github/workspace/components/FeedbackModalContent.vue').default,BaseCardFullScreen: require('/github/workspace/components/BaseCardFullScreen.vue').default})
