import { ref, Ref, useContext } from '@nuxtjs/composition-api'
import { components } from '~/types/generated/schema'
import { RemovableRef, useSessionStorage } from '@vueuse/core'
import { Fetcher } from '~/lib/app/fetcher'

const adminPositionsState = ref<AdminPositionsStoreState | null>(null)

export const useAdminPositionsStore = () => {
  const {
    $app: { user, fetcher },
  } = useContext()

  if (!user.isAdmin) {
    throw new Error('not an admin')
  }

  return new AdminPositionsStore(adminPositionsState, fetcher)
}

interface AdminPositionsStoreState {
  positions: components['schemas']['GetPositionsResponse']
  includesTest: boolean
}

class AdminPositionsStore {
  constructor(
    private readonly _state: RemovableRef<AdminPositionsStoreState | null>,
    private readonly fetcher: Fetcher,
  ) {}

  get positions() {
    return this._state.value?.positions?.positions ?? []
  }

  getPositionById(positionGuid: string) {
    return this.positions.find((pos) => pos.externalId === positionGuid)
  }

  async loadIfNecessary(includeTest?: boolean) {
    const doIncludeTest = !!includeTest
    if (this._state.value != null && this._state.value.includesTest == doIncludeTest) return

    const query = new URLSearchParams()
    query.set('OrderBy', 'CompanyName')
    query.set('Order', 'Asc')
    if (doIncludeTest) {
      query.set('IncludeTest', 'true')
    }

    this._state.value = {
      positions: await this.fetcher.$get('/api/v2/positions', { query }),
      includesTest: doIncludeTest,
    }
  }
}
