import { ref, useContext } from '@nuxtjs/composition-api'
import { Context } from '@nuxt/types'

import { runOnceFn } from '~/lib/utils/runOnceFn'

export function usePaymentDetailsStore() {
  const ctx = useContext()
  return new PaymentDetailsStore(ctx)
}

class PaymentDetailsStore {
  private readonly _loadingSelfServicePortal = ref(false)

  constructor(private readonly ctx: ReturnType<typeof useContext>) {}

  async goToSelfServicePortal() {
    try {
      this._loadingSelfServicePortal.value = true
      const portal = await this.ctx.$fetcher.$get(`/api/chargebeeportals/selfservice`, {
        query: {
          redirectUrl: `${location.protocol}//${location.hostname}:${location.port}${location.pathname}`,
        },
      })
      window.location.href = portal.url!
    } catch (e) {
      this._loadingSelfServicePortal.value = false
    }
  }
}
