import { Context } from '@nuxt/types'

/**
 * Stop unauthenticated users from access protected pages (all pages except jobs board and hotlist candidate profiles)
 */
export default async function ensureUserAuth({ $app: { auth }, redirect, route }: Context) {
  const user = await auth.getUser()
  const allowedRoutes = ['jobs', 'jobs-id', 'hotlist-candidates-candidateId']

  if (user == null && !allowedRoutes.includes(route.name ?? '')) {
    redirect(`/logout`, {
      redirect: route.fullPath,
    })
  }
}
