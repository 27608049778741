import { zip } from '~/lib/utils/zip'

export const None = 'NONE'

export enum HighestDegree {
  Msa = 'MSA',
  Abitur = 'ABI',
  Ausbildung = 'AA',
  Bachelor = 'BAC',
  Master = 'MA',
  Phd = 'PHD',
}

export const HighestDegreeValues: ReadonlyArray<HighestDegree> = Object.values(HighestDegree)

export enum SeniorityLevel {
  None = 'NONE',
  Junior = '1T2YEAR',
  MidLevel = '3T5YEAR',
  Senior = 'O5YEAR',
  Principal = 'O10YEAR',
}

export const SeniorityLevelValues: ReadonlyArray<SeniorityLevel> = Object.values(SeniorityLevel)

export enum TeamSize {
  OneToThree = '1T3',
  ThreeToFive = '3T5',
  FiveToSeven = '5T7',
  SevenToTen = '7T10',
  TenToTwenty = '10T20',
  TwentyToFifty = '20T50',
  FiftyToHundred = '50T100',
  OverHundred = 'O100',
}

export const TeamSizeValues: ReadonlyArray<TeamSize> = Object.values(TeamSize)

export enum LanguageOld {
  German = 'Deutsch',
  English = 'ENG',
  Turkish = 'TR',
  Russian = 'RU',
  Arabic = 'AR',
  Spanish = 'ESP',
  Italian = 'ITA',
  French = 'FR',
  Other = 'Andere',
}

export enum Language {
  German = 'Deutsch',
  English = 'Englisch',
  Turkish = 'Türkisch',
  Russian = 'Russisch',
  Arabic = 'Arabisch',
  Spanish = 'Spanisch',
  Italian = 'Italienisch',
  French = 'Französisch',
  Vietnamese = 'Vietnamesisch',
  Hindi = 'Hind',
  Urdu = 'Urdu',
  Persian = 'Persisch',
  Other = 'Andere',
}

export const LanguageValues: ReadonlyArray<Language> = Object.values(Language)
export const LanguageOldValues: ReadonlyArray<LanguageOld> = Object.values(LanguageOld)

export const OldToNewLanguageEnumMapping = Object.freeze(
  Object.fromEntries(zip(LanguageOldValues, LanguageValues)),
)

export enum LanguageLevelOld {
  C1 = 'C1',
  C2 = 'C2',
}

export enum LanguageLevel {
  Native = 'C2',
  Proficient = 'C1',
  Skilled = 'B',
  Beginner = 'A',
  None = 'NONE',
}

export const LanguageLevelValues: ReadonlyArray<LanguageLevel> = Object.values(LanguageLevel)

export enum SearchActivity {
  Active = 'ACTIVE',
  OpenForOffers = 'OFN',
  Inactive = 'NONE',
}

export const SearchActivityValues = Object.values(SearchActivity)

export enum Discoverability {
  Yes = 'YES',
  No = 'NONE',
}

export const DiscoverabilityValues = Object.values(Discoverability)

export enum NoticePeriod {
  None = 'NONE',
  OneMonth = 'ONEMONTH',
  TwoMonths = 'TWOMONTHS',
  ThreeMonths = 'THREEMONTHS',
  ThreeToSixMonths = 'THREETOSIXMONTHS',
  SixOrMoreMonths = 'SIXORMORE',
}

export const NoticePeriodValues = Object.values(NoticePeriod)

export enum EarliestStart {
  WithinTwoWeeks = 'TWOWEEKS',
  WithinThreeMonths = 'THREEMONTHS',
  BetweenThreeAndSixMonths = 'BTHREEANDSIXMONTHS',
  SixOrMoreMonths = 'SIXORMORE',
}

export const EarliestStartValues = Object.values(EarliestStart)

export enum ManagedTeams {
  SalesReps = 'SDR',
  AccountManagers = 'AM',
  AccountExecutives = 'AE',
  WholeDepartment = 'DEPT',
  Other = 'OTHER',
}

export enum ManagedTeamsOld {
  SalesReps = 'REPS',
  AccountManagers = 'AMT',
  AccountExecutives = 'AE',
  WholeDepartment = 'DEP',
  Other = 'OTHER',
}

export const ManagedTeamsValues = Object.values(ManagedTeams)

export enum LocationsOfInterest {
  Remote = 'RM',
  Berlin = 'BER',
  Bonn = 'BO',
  FrankfurtAmMain = 'FFM',
  Dusseldorf = 'DD',
  Essen = 'ES',
  Hamburg = 'HAM',
  Heidelberg = 'HDLBG',
  Koeln = 'COL',
  Leipzig = 'LP',
  Muenchen = 'MUC',
  Stuttgart = 'STU',
  Hannover = 'HAN',
  Andere = 'OTHER',
}

export const LocationsOfInterestValues = Object.values(LocationsOfInterest)

export enum LocationsOfInterestOld {
  Remote = 'RM',
  Uberall = 'EVERYWHERE',
}

export enum SalesIndustries {
  Agriculture = 'Agriculture',
  Automotive = 'Automotive',
  Construction = 'Construction',
  Education = 'Education',
  Chemicals = 'Chemicals',
  Electronics = 'Electronics',
  Energy = 'Energy',
  HeavyIndustry = 'HeavyIndustry',
  Manufacturing = 'Manufacturing',
  Finance = 'Finance',
  ConsumerGoods = 'ConsumerGoods',
  Leisure = 'Leisure',
  RealEstate = 'RealEstate',
  Logistics = 'Logistics',
  HumanResources = 'HumanResources',
  Health = 'Health',
  ProfessionalServices = 'ProfessionalServices',
  Law = 'Law',
  Media = 'Media',
  Software = 'Software',
  Telecomms = 'Telecomms',
  Hospitality = 'Hospitality',
  PublicSector = 'PublicSector',
  Textiles = 'Textiles',
}

export const SalesIndustriesValues = Object.values(SalesIndustries)

export enum SoldProducts {
  Saas = 'SAAS',
  TransactionalDigitalGoods = 'TD',
  RetailDistribution = 'VEH',
  PersonellServices = 'PD',
  PhysicalGoods = 'PG',
  PersonalServices = 'PDL',
  MarketPlace = 'MP',
  TelecommContracts = 'TKV',
  PromotionOrFundraising = 'PF',
  SolarEnergy = 'SolarEnergy',
  Bildungsgutscheine = 'Bildungsgutscheine',
  Insurance = 'Insurance',
}

export const SoldProductsValues = Object.values(SoldProducts)

export enum TalentProfileStatuses {
  Approved = 'Approved',
  Rejected = 'Rejected',
  PendingApproval = 'PendingApproval',
  Draft = 'Draft',
}

export const TalentProfileStatusValues = Object.values(TalentProfileStatuses)

export enum MotCallFlow {
  Mandatory = 'Mandatory',
  Optional = 'Optional',
}

export enum Positions {
  SDR = 'SDR',
  CSM = 'CSM',
  BDR = 'BDR',
  AccountExecutive = 'AccountExecutive',
  SalesManager = 'SalesManager',
  AccountManager = 'AccountManager',
  SalesTeamLead = 'SalesTeamLead',
  Graduate = 'Graduate',
  Other = 'Other',
}

export const PositionsValues = Object.values(Positions)

export enum JobInterestLevels {
  None = 'NONE',
  Unwilling = 'Unwilling',
  Normal = 'Normal',
  Gladly = 'Gladly',
  VeryGladly = 'VeryGladly',
}

export const JobInterestLevelsValues = Object.values(JobInterestLevels)

export enum Salaries {
  T30K = '30K',
  F30T40 = '30T40',
  F40T50 = '40T50',
  F50T60 = '50T60',
  F60T70 = '60T70',
  F70T80 = '70T80',
  F80T90 = '80T90',
  F90T100 = '90T100',
  F100T110 = '100T110',
  F110T120 = '110T120',
  OVER120 = 'OVER120',
}

export const SalariesValues = Object.values(Salaries)

export function salaryAsRange(salary: string) {
  return {
    [Salaries.T30K]: { min: 0, max: 30 },
    [Salaries.F30T40]: { min: 30, max: 40 },
    [Salaries.F40T50]: { min: 40, max: 50 },
    [Salaries.F50T60]: { min: 50, max: 60 },
    [Salaries.F60T70]: { min: 60, max: 70 },
    [Salaries.F70T80]: { min: 70, max: 80 },
    [Salaries.F80T90]: { min: 80, max: 90 },
    [Salaries.F90T100]: { min: 90, max: 100 },
    [Salaries.F100T110]: { min: 100, max: 110 },
    [Salaries.F110T120]: { min: 110, max: 120 },
    [Salaries.OVER120]: { min: 120 },
  }[salary]
}

export function salaryAsRangeFull(salary: string) {
  return {
    [Salaries.T30K]: { min: 0, max: 30000 },
    [Salaries.F30T40]: { min: 30000, max: 40000 },
    [Salaries.F40T50]: { min: 40000, max: 50000 },
    [Salaries.F50T60]: { min: 50000, max: 60000 },
    [Salaries.F60T70]: { min: 60000, max: 70000 },
    [Salaries.F70T80]: { min: 70000, max: 80000 },
    [Salaries.F80T90]: { min: 80000, max: 90000 },
    [Salaries.F90T100]: { min: 90000, max: 100000 },
    [Salaries.F100T110]: { min: 100000, max: 110000 },
    [Salaries.F110T120]: { min: 110000, max: 120000 },
    [Salaries.OVER120]: { min: 120000 },
  }[salary]
}

export enum TalentJobExperience {
  ColdCalling = 'ColdCalling',
  SaasClosing = 'SaasClosing',
  Closing = 'Closing',
  SaasAccountManagement = 'SaasAccountManagement',
  NonSaasAccountManagement = 'NonSaasAccountManagement',
  AccountManagement = 'AccountManagement',
  Leadership = 'Leadership',
}

export enum TalentJobInterest {
  ColdCalling = 'ColdCalling',
  SaasClosing = 'SaasClosing',
  Closing = 'Closing',
  SaasAccountManagement = 'SaasAccountManagement',
  NonSaasAccountManagement = 'NonSaasAccountManagement',
  Leadership = 'Leadership',
}

export enum WillingRemote {
  Yes = 'YES',
  No = 'NO',
}

export const WillingRemoteValues = Object.values(WillingRemote)

export enum AgeCategory {
  F18T20 = '18T20',
  F20T22 = '20T22',
  F22T25 = '22T25',
  F25T30 = '25T30',
  F30T40 = '30T40',
  F40T50 = '40T50',
  OVER50 = 'OVER50',
}

export const ageCategoryOpts = {
  '18T20': '18-20',
  '20T22': '20-22',
  '22T25': '22-25',
  '25T30': '25-30',
  '30T40': '30-40',
  '40T50': '40-50',
  OVER50: '50+',
}

export const AgeCategoryValues = Object.values(AgeCategory)

export enum PreviousRoles {
  WorkingStudent = 'WorkingStudent',
  SalesManager = 'SalesManager',
  Aussendienstler = 'Aussendienstler',
  SdrBdr = 'SdrBdr',
  AccountExecutive = 'AccountExecutive',
  CustomerSuccess = 'CustomerSuccess',
  AccountManager = 'AccountManager',
  TeamManager = 'TeamManager',
}

export function getPrioritisedPreviousRole(roles: (PreviousRoles | string)[]): string {
  const rolesInOrderOfPriority = [
    PreviousRoles.TeamManager,
    PreviousRoles.AccountManager,
    PreviousRoles.CustomerSuccess,
    PreviousRoles.AccountExecutive,
    PreviousRoles.SdrBdr,
    PreviousRoles.Aussendienstler,
    PreviousRoles.SalesManager,
    PreviousRoles.WorkingStudent,
  ]

  const priorities = roles
    .map((role) => rolesInOrderOfPriority.indexOf(role as any))
    .filter((idx) => idx > -1)

  return rolesInOrderOfPriority[Math.min(...priorities)] ?? roles[0]
}

export const PreviousRolesValues = Object.values(PreviousRoles)

export enum YearsOfExperience {
  None = 'NONE',
  SixMonths = '6MONTHS',
  OneYear = '1YEAR',
  TwoYears = '2YEAR',
  ThreeYears = '3YEAR',
  ThreeToFiveYears = '3TO5YEAR',
  OverFiveYears = 'OVER5YEAR',
}

export enum YearsOfExperienceOld {
  TwoMonths = '2MONTHS',
  OneAndAHalfYears = '1ANDHALFYEAR',
  ThreeToFourYears = '3TO4YEAR',
  FiveToSixYears = '5TO6YEAR',
  SevenToNineYears = '7TO9YEAR',
  TenPlusYears = '10PLUS',
}

export const YearsOfExperienceValues = Object.values(YearsOfExperience)

export enum PreviousWorkExperience {
  Retail = 'Retail',
  Sales = 'Sales',
  Other = 'Other',
}

export const PreviousWorkExperienceValues = Object.values(PreviousWorkExperience)

export enum TalentIcp {
  Junior = 'Junior',
  MidLevel = 'MidLevel',
  Senior = 'Senior',
  None = 'None',
}

export enum TalentSeniority {
  Junior = 'Junior',
  MidLevel = 'MidLevel',
  Senior = 'Senior',
}

export enum OpportunityType {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Freelance = 'Freelance',
  Other = 'Other',
}

export const OpportunityTypeValues = Object.values(OpportunityType)
