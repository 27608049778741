import { Context } from '@nuxt/types'

/**
 * Setup any global tracking state and trigger any events and what not
 */

export default function globalAnalyticsMiddleware(context: Context) {
  const {
    $app: { analytics },
  } = context

  analytics.track_pageview()
  analytics.register({ WebappPage: context.route.name })

  const searchParams = new URLSearchParams(location.search)

  // Here we are tracking some of the stuff about the position that was pitched and the template they get in whatsapp
  // TODO this kind of stuff might be better handled with a link shortener that does tracking for us

  const pitchedPositionSlug = searchParams.get('pitched')
  if (pitchedPositionSlug) analytics.register({ PitchedPositionSlug: pitchedPositionSlug }, 1)

  const pitchTemplate = searchParams.get('template')
  if (pitchTemplate) analytics.register({ PitchTemplate: pitchTemplate }, 1)

  const whatsappType = searchParams.get('wa')
  if (whatsappType) analytics.register({ WhatsappType: whatsappType })

  const utmCampaign = searchParams.get('utm_campaign')
  const utmMedium = searchParams.get('utm_medium')
  const utmSource = searchParams.get('utm_source')
  const utmTerm = searchParams.get('utm_term')
  const utmContent = searchParams.get('utm_content')

  if (utmCampaign) {
    analytics.people.set('UtmCampaign', utmCampaign)
    analytics.people.set_once('SignupUtmCampaign', utmCampaign)
    analytics.people.append('AllUtmCampaign', utmCampaign)
  }
  if (utmMedium) {
    analytics.people.set('UtmMedium', utmMedium)
    analytics.people.set_once('SignupUtmMedium', utmMedium)
    analytics.people.append('AllUtmMedium', utmMedium)
  }
  if (utmSource) {
    analytics.people.set('UtmSource', utmSource)
    analytics.people.set_once('SignupUtmSource', utmSource)
    analytics.people.append('AllUtmSource', utmSource)
  }
  if (utmTerm) {
    analytics.people.set('UtmTerm', utmTerm)
    analytics.people.set_once('SignupUtmTerm', utmTerm)
    analytics.people.append('AllUtmTerm', utmTerm)
  }
  if (utmContent) {
    analytics.people.set('UtmContent', utmContent)
    analytics.people.set_once('SignupUtmContent', utmContent)
    analytics.people.append('AllUtmContent', utmContent)
  }
}
