
import ArrowLeftSolidIcon from '@/assets/img/ArrowLeftSolidIcon.svg'

export default {
  components: {
    ArrowLeftSolidIcon,
  },
  data: () => ({
    backBtn: {
      icon: 'ArrowLeftSolidIcon',
      label: 'Back',
    },
  }),
}
