import { onMounted, onUnmounted } from '@nuxtjs/composition-api'

interface UseNuxtListeners {
  selected: (val: any) => void
  'cancel-pressed': () => void
  'save-pressed': () => void
  'section-edited': () => void
  'scroll-to': (elementId: string) => void
  'scroll-to-top': (opts?: { smooth?: boolean }) => void
  'get-save-data': () => void
  'show-fullscreen-gallery': (imageUrls: Array<string>, imgClasses?: string) => void
  'updated-timeline': (...args: any[]) => void
  'update-candidate-status': (...args: any[]) => void
  'show-fullscreen-onboarding-video': () => void
  'application-updated': () => void
}

/**
 * Typesafe wrapper for `$nuxt.$on`
 * The problem with nuxt.on is that it is seemingly too easy for people to use that instead instead of emitting from the component
 * so you end up with some crazy spaghetti where we didn't need it. Global event busses should be used for communicating between separate parts of a system,
 * not for communicating from child components to their parents, i.e. just making it quicker to slap together some garbage Vue code
 * @deprecated setup listeners on the messageBus during app initialization instead
 */
export const useNuxtListeners = (listeners: Partial<UseNuxtListeners>) => {
  onMounted(() => {
    for (const [event, listener] of Object.entries(listeners)) {
      window.$nuxt.$on(event, listener)
    }
  })

  onUnmounted(() => {
    for (const event of Object.keys(listeners)) {
      window.$nuxt.$off(event)
    }
  })
}

/**
 * typesafe wrapper for `$nuxt.$emit`
 * @deprecated use $app.messageBus.publish instead
 */
export const useNuxtEmit =
  () =>
  <K extends keyof UseNuxtListeners>(event: K, ...args: Parameters<UseNuxtListeners[K]>) => {
    window.$nuxt.$emit(event, ...args)
  }
