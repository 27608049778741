import { ref, Ref, watch } from '@nuxtjs/composition-api'
import { Awaitable } from '@vueuse/core'

export const enum NotificationType {
  Success,
  Error,
  Info,
}

export interface Notification {
  type: NotificationType
  title: string
  message: string
  timeoutMs?: number
  button?: {
    label: string
    icon: 'clipboard' | null
    action: () => void
  }
}

export interface SavingState {
  saveButtonText: string
  cancelButtonText: string
  formId?: string
  onSave?: (event: Event) => Awaitable<void>
  onCancel: (event: Event) => Awaitable<void>
}

type PartialNotification = Partial<Omit<Notification, 'type' | 'message'>> & {
  message: string
}

export class NotificationStore {
  constructor(
    private readonly _notifications: Ref<Array<{ notification: Notification }>>,
    private readonly _isSaving: Ref<boolean>,
    private readonly _savingState: Ref<SavingState | null>,
    private readonly _onboardingHelpWindowIsShowing: Ref<boolean>
  ) {}

  static init(): NotificationStore {
    const notif = new NotificationStore(ref([]), ref(false), ref(null), ref(false))

    watch(
      () => notif.savingState?.formId,
      (formId) => {
        if (formId) {
          const form = document.querySelector(`form[id=${formId}]`)
          form?.addEventListener(
            'submit',
            () => {
              notif.hideSaveButtons()
            },
            { once: true }
          )
        }
      }
    )

    return notif
  }

  get notifications() {
    return this._notifications.value.map((n) => {
      return {
        notification: n.notification,
        close: () => {
          this._notifications.value = this._notifications.value.filter((n) => n !== n)
        },
      }
    })
  }

  get isSaving() {
    return this._isSaving.value
  }

  get savingState() {
    return this._savingState.value
  }

  get onboardingHelpWindowIsShowing() {
    return this._onboardingHelpWindowIsShowing.value
  }

  set onboardingHelpWindowIsShowing(value: boolean) {
    this._onboardingHelpWindowIsShowing.value = value
  }

  showInfo(notif: PartialNotification | string) {
    return this.showMessage({
      type: NotificationType.Info,
      title: typeof notif !== 'string' && notif.title != null ? notif.title : 'Info',
      message: typeof notif === 'string' ? notif : notif.message,
    })
  }

  showSuccess(notif: PartialNotification | string) {
    return this.showMessage({
      type: NotificationType.Success,
      title: typeof notif !== 'string' && notif.title != null ? notif.title : 'Erfolg',
      message: typeof notif === 'string' ? notif : notif.message,
    })
  }

  showError(notif: PartialNotification | string) {
    const data = typeof notif === 'string' ? { message: notif } : notif
    return this.showMessage(Object.assign({ type: NotificationType.Error, title: 'Fehler' }, data))
  }

  showSaveButtons(params: {
    saveText?: string
    cancelText?: string
    onSave?: (event: Event) => Awaitable<void>
    onCancel?: (event: Event) => Awaitable<void>
    formId?: string
  }) {
    const onSave = params.onSave

    this._savingState.value = {
      saveButtonText: params.saveText ?? 'Save',
      cancelButtonText: params.cancelText ?? 'Clear',
      formId: params.formId,
      onSave: onSave
        ? async (event: Event) => {
            this._isSaving.value = true
            try {
              await onSave(event)
            } catch (e) {
              console.error('save error', e)
            } finally {
              this._isSaving.value = false
              this._savingState.value = null
            }
          }
        : undefined,
      onCancel: async (event: Event) => {
        try {
          await params.onCancel?.(event)
        } catch (e: any) {
          console.error('cancel error', e)
        } finally {
          this._isSaving.value = false
          this._savingState.value = null
        }
      },
    }
    this._isSaving.value = false
  }

  hideSaveButtons() {
    this._savingState.value = null
  }

  private showMessage(notification: Notification) {
    // we only want to show a single notification at any one time.
    // TODO we probably don't need to have an array of notifications anymore (it was possible to show multiple notifs)
    //      leave it like so for now since it's nbd
    this._notifications.value = []

    const handle = { notification }

    const removeNotification = () => {
      this._notifications.value = this._notifications.value.filter((n) => n !== handle)
    }

    setTimeout(removeNotification, notification.type === NotificationType.Error ? 15000 : 5000)

    this._notifications.value = [...this._notifications.value, handle]

    return removeNotification
  }
}

/** Some convenience globals just to help reduce the scope of refactoring */
export function showError(notif: PartialNotification | string) {
  return window.$nuxt.$notif.showError(notif)
}

export function showSuccess(notif: PartialNotification | string) {
  return window.$nuxt.$notif.showSuccess(notif)
}

export function showInfo(notif: PartialNotification | string) {
  return window.$nuxt.$notif.showInfo(notif)
}

export function showSaveButtons(params: {
  saveText?: string
  cancelText?: string
  onSave?: (event: Event) => Awaitable<void>
  onCancel?: (event: Event) => Awaitable<void>
  formId?: string
}) {
  return window.$nuxt.$notif.showSaveButtons(params)
}

export function hideSaveButtons() {
  return window.$nuxt.$notif.hideSaveButtons()
}
