import { UserStore, UserType } from '~/store/userStore'
import mixpanel from 'mixpanel-browser'
import { AppMessageConsumer } from '~/lib/app/appState'
import { AuthService } from '~/lib/app/auth'

export function identifyUserOnUserDataLoadedHandler(
  user: UserStore,
  auth: AuthService
): AppMessageConsumer<'user-data-loaded'> {
  return () => {
    if (user.userType === UserType.Recruiter) {
      mixpanel.identify(user.companyId)
    } else {
      mixpanel.identify(user.impersonatedTalentFirebaseId ?? auth.currentUser?.uid)
    }

    const impersonator = user.impersonator

    mixpanel.register({
      IsImpersonated: impersonator != null,
      ImpersonatedBy: impersonator?.firebaseId,
      ImpersonatorEmail: impersonator?.email,
    })
  }
}
