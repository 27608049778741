import { render, staticRenderFns } from "./FeedbackModalContent.vue?vue&type=template&id=71758edd"
import script from "./FeedbackModalContent.vue?vue&type=script&lang=ts&setup=true"
export * from "./FeedbackModalContent.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./FeedbackModalContent.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseWrapperCard: require('/github/workspace/components/BaseWrapperCard.vue').default})
