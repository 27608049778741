import {
  DeleteRequestContent,
  DeleteResponseContent,
  GetResponseContent,
  Path,
  PostRequestContent,
  PostResponseContent,
  PutRequestContent,
  PutResponseContent,
} from '~/lib/app/apiTypes'

// TODO add type for body params (instead of unknown, we can get it from schema.ts)

export class FetcherError extends Error {
  public readonly status: number
  public readonly requestUrl: string
  public readonly jsonBody: any

  constructor(response: Response, jsonBody: any) {
    super(`Error code ${response.status}`)
    // we manually set the prototype of the new object to make `instanceof` work properly.
    // Typescript generates code in such a way that, extending builtins (Error, Array, Map, etc) doesn't work as expected (because of reasons).
    // read about it here: https://github.com/microsoft/TypeScript-wiki/blob/81fe7b91664de43c02ea209492ec1cea7f3661d0/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, FetcherError.prototype)
    this.status = response.status
    this.requestUrl = response.url
    this.jsonBody = jsonBody
  }

  get code(): string {
    return this.jsonBody?.error ?? 'unknown-error'
  }
}

type GetRequestInit = Omit<RequestInit, 'method' | 'body'> & GetConfigExtra

interface GetConfigExtra {
  // TODO Use the query type from components.schemas
  query?: URLSearchParams | Record<string, string[] | string | null | undefined>
}

// TODO there is the library openapi-fetcher which we could possibly use instead.:
//          https://openapi-ts.dev/openapi-fetch/
//      would need to do some lot of conversion idk might not be worth the effort idk
export interface Fetcher {
  $get<GetPath extends Path<'get'>>(
    path: GetPath,
    requestInit?: GetRequestInit
  ): Promise<GetResponseContent<GetPath>>

  $post<PostPath extends Path<'post'>>(
    path: PostPath,
    body: PostRequestContent<PostPath>,
    requestInit?: Omit<RequestInit, 'method' | 'body'> & {
      // todo use the query type from components.schemas
      query?: URLSearchParams | Record<string, string>
    }
  ): Promise<PostResponseContent<PostPath>>

  $put<PutPath extends Path<'put'>>(
    path: PutPath,
    body?: PutRequestContent<PutPath>,
    requestInit?: Omit<RequestInit, 'method' | 'body'> & {
      query?: URLSearchParams | Record<string, string>
    }
  ): Promise<PutResponseContent<PutPath>>

  $delete<DeletePath extends Path<'delete'>>(
    path: DeletePath,
    body?: DeleteRequestContent<DeletePath>,
    requestInit?: Omit<RequestInit, 'method' | 'body'> & {
      query?: URLSearchParams | Record<string, string>
    }
  ): Promise<DeleteResponseContent<DeletePath>>
}

export interface BeforeRequestHandler {
  (beforeRequestData: { headers: Headers; searchParams: URLSearchParams }): Promise<void>
}

interface AfterRequestData {
  status: number
  requestBody?: string
  responseBody: string
  requestUrl: string
}

export interface AfterRequestHandler {
  (afterRequestData: AfterRequestData): Promise<void>
}

export interface FetcherInit {
  onBeforeRequest(handler: BeforeRequestHandler): void

  onAfterRequest(handler: AfterRequestHandler): void
}

export class FetcherImpl implements Fetcher, FetcherInit {
  private readonly beforeRequestHandlers: Array<BeforeRequestHandler> = []
  private readonly afterRequestHandlers: Array<AfterRequestHandler> = []

  constructor(public readonly baseUrl: string) {
    console.debug('apiUrl:', this.baseUrl)
  }

  async $get<GetPath extends Path<'get'>>(
    path: GetPath,
    requestInit?: GetRequestInit
  ): Promise<GetResponseContent<GetPath>> {
    const headers = new Headers(requestInit?.headers ?? {})
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')

    let search: URLSearchParams
    if (requestInit?.query instanceof URLSearchParams) {
      search = new URLSearchParams(requestInit.query)
    } else {
      search = new URLSearchParams()
      for (const [k, v] of Object.entries(requestInit?.query ?? {})) {
        if (v != null) {
          // hah alol
          ;[v].flat().forEach(search.append.bind(search, k))
        }
      }
    }

    return await this.fetch(
      `${path}?${search.toString()}`,
      Object.assign({}, requestInit ?? {}, { method: 'GET', headers })
    )
  }

  async $post<PostPath extends Path<'post'>>(
    path: PostPath,
    body: PostRequestContent<PostPath>,
    requestInit?: Omit<RequestInit, 'method' | 'body'> & {
      // todo use the query type from components.schemas
      query?: URLSearchParams | Record<string, string>
    }
  ): Promise<PostResponseContent<PostPath>> {
    const headers = new Headers(requestInit?.headers ?? {})
    headers.set('Accept', 'application/json')
    // if we are sending a form data then we leave the content-type undefined so that the browser will automatically set correct Content-Type as well as Content-Type boundary
    // if we manually set Content-Type: multipart/form-data then the browser will not set other required headers and our request will break
    if (!(body instanceof FormData)) {
      headers.set('Content-Type', 'application/json')
    }

    const requestBody = !body
      ? undefined
      : !(body instanceof FormData)
      ? JSON.stringify(body)
      : body

    const search = new URLSearchParams(requestInit?.query ?? {})
    return await this.fetch(
      `${path}?${search.toString()}`,
      Object.assign({}, requestInit ?? {}, {
        body: requestBody,
        method: 'POST',
        headers,
      })
    )
  }

  async $put<PutPath extends Path<'put'>>(
    path: PutPath,
    body?: PutRequestContent<PutPath>,
    requestInit?: Omit<RequestInit, 'method' | 'body'> & {
      query?: URLSearchParams | Record<string, string>
    }
  ): Promise<PutResponseContent<PutPath>> {
    const headers = new Headers(requestInit?.headers ?? {})
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')

    const search = new URLSearchParams(requestInit?.query ?? {})
    return await this.fetch(
      `${path}?${search.toString()}`,
      Object.assign({}, requestInit ?? {}, {
        body: body ? JSON.stringify(body) : undefined,
        method: 'PUT',
        headers,
      })
    )
  }

  async $delete<DeletePath extends Path<'delete'>>(
    path: DeletePath,
    body?: DeleteRequestContent<DeletePath>,
    requestInit?: Omit<RequestInit, 'method' | 'body'> & {
      query?: URLSearchParams | Record<string, string>
    }
  ): Promise<DeleteResponseContent<DeletePath>> {
    const headers = new Headers(requestInit?.headers ?? {})
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')

    const search = new URLSearchParams(requestInit?.query ?? {})
    return await this.fetch(
      `${path}?${search.toString()}`,
      Object.assign({}, requestInit ?? {}, {
        body: JSON.stringify(body),
        method: 'DELETE',
        headers,
      })
    )
  }

  private async fetch(path: string, requestInit: RequestInit) {
    const headers = new Headers(requestInit.headers ?? {})

    const requestUrl = new URL(path, this.baseUrl)

    for (const handler of this.beforeRequestHandlers) {
      await handler({ headers, searchParams: requestUrl.searchParams }).catch((e) =>
        console.error(e)
      )
    }

    const response = await fetch(requestUrl, Object.assign(requestInit, { headers }))

    let json: any
    try {
      json = await response.json()
    } catch {
      json = {}
    }

    if (!response?.ok) {
      throw new FetcherError(response, json)
    }

    for (const handler of this.afterRequestHandlers) {
      await handler({
        status: response.status,
        requestUrl: requestUrl.toString(),
        responseBody: json,
        // todo include request body
      }).catch((e) => console.error(e))
    }

    return json
  }

  // it's a bit annoying to do some of the injection of the user store etc.
  // instead we just do some custom before request listener stuff so we don't have to inject everything in the ctor
  public onBeforeRequest(handler: BeforeRequestHandler) {
    this.beforeRequestHandlers.push(handler)
  }

  public onAfterRequest(handler: AfterRequestHandler) {
    this.afterRequestHandlers.push(handler)
  }
}
