import { AppMessageConsumer } from '~/lib/app/appState'
import { Analytics } from '~/lib/app/analytics'

export function trackTalentReactivationInMixpanelHandler(
  mxp: Analytics
): AppMessageConsumer<'talent-reactivation-detected'> {
  return ({ daysOfInactivity }) => {
    console.debug('reactivation detected. days of inactivity', daysOfInactivity)
    mxp.track('Talent Reactivated', { DaysOfInactivity: daysOfInactivity })
    mxp.register({ IsReactivatedTalent: true })
  }
}
