import { Context } from '@nuxt/types'
import { UserType } from '~/store/userStore'

export default function hyreAdminOnlyMiddleware(context: Context) {
  // If a user somehow stumbles upon the hyre admin pages then gently turn them back
  // We should make sure that there is always proper authz in the backend anyway
  if (
    context.route.path.startsWith('/hyreadmin') &&
    context.$app.user.userType !== UserType.HyreAdmin
  ) {
    context.redirect('/')
  }
}
