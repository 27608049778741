import { onMounted, onUnmounted } from '@nuxtjs/composition-api'

export const useAnimate = (animationFn: (ticks: number) => void) => {
  let id: number | null = null
  let ticks = 0
  onMounted(() => {
    id = window.requestAnimationFrame(function animate() {
      animationFn(ticks)
      ticks += 1
      id = window.requestAnimationFrame(animate)
    })
  })

  onUnmounted(() => {
    if (id != null) {
      window.cancelAnimationFrame(id)
    }
  })
}
