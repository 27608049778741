import { ref, unref, watch } from '@nuxtjs/composition-api'
import { MaybeRef } from '@vueuse/core'

interface UseDropdownControlOpts {
  onClose?: () => void
}

export function useDropdownControl(opts?: UseDropdownControlOpts) {
  const isOpen = ref(false)

  watch(
    () => isOpen.value,
    (isOpen, wasOpen) => {
      if (!isOpen && wasOpen) {
        opts?.onClose?.()
      }
    }
  )

  return {
    get isOpen() {
      return isOpen.value
    },
    set isOpen(open: boolean) {
      isOpen.value = open
    },
    /**
     * Props to pass to v-bind
     */
    get props() {
      return {
        'is-open': isOpen.value,
      }
    },

    /**
     * Listeners to pass to v-on
     */
    get listeners() {
      return {
        clicked: () => {
          isOpen.value = !isOpen.value
        },
        'clicked-outside': () => {
          isOpen.value = false
        },
      }
    },
  }
}
