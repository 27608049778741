import { render, staticRenderFns } from "./HyreApprovedModal.vue?vue&type=template&id=018ae896"
import script from "./HyreApprovedModal.vue?vue&type=script&lang=ts&setup=true"
export * from "./HyreApprovedModal.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseModal: require('/github/workspace/components/BaseModal.vue').default})
