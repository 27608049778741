import { Context } from '@nuxt/types'

/**
 * Stop unauthenticated users from access protected pages (all pages except jobs board and hotlist candidate profiles)
 */
export default async function goToAppIfLoggedIn({ $app: { user }, redirect }: Context) {
  if (user.isUserLoggedIn) {
    redirect('/')
  }
}
