import { ref, Ref, useContext } from '@nuxtjs/composition-api'
import { showKomboConnect } from '@kombo-api/connect'
import { showError } from '~/store/notificationStore'
import { components } from '~/types/generated/schema'
import { doWithRetry } from '~/lib/utils/doWithRetry'

export type AtsJob = components['schemas']['AtsJob']

const JobPageSize = 100

interface AtsIntegrationStoreState {
  jobs: AtsJob[]
  nextPage: string | null
  didLoadAllPages: boolean
  loadingState: 'init' | 'loading' | 'success' | 'error'
  remoteIdFilter: string | null
  jobNameFilter: string | null
  includeInactiveFilter: boolean
}

function emptyState(): AtsIntegrationStoreState {
  return {
    jobs: [],
    loadingState: 'init',
    nextPage: null,
    didLoadAllPages: false,
    remoteIdFilter: null,
    jobNameFilter: null,
    includeInactiveFilter: false,
  }
}

export function useAtsIntegrationStore() {
  const ctx = useContext()

  return new AtsIntegrationStore(ctx, ref<AtsIntegrationStoreState>(emptyState()))
}

export class AtsIntegrationStore {
  private working = false

  constructor(
    private readonly ctx: ReturnType<typeof useContext>,
    private readonly _state: Ref<AtsIntegrationStoreState>
  ) {}

  get isIntegrated() {
    return !!this.ctx.$user.companyData?.atsIntegrationId
  }

  get integratedTool() {
    return this.ctx.$user.companyData?.atsIntegrationTool
  }

  get currentJobsPage(): AtsJob[] {
    return this._state.value.jobs.slice()
  }

  get isLastJobsPage() {
    return this._state.value.didLoadAllPages
  }

  get loadingState() {
    return this._state.value.loadingState
  }

  private get companyGuid() {
    if (!this.ctx.$user.isCompany) throw new Error('not a company!')
    return this.ctx.$user.companyId
  }

  async getJobById(jobId: string): Promise<AtsJob | undefined> {
    return this.currentJobsPage.find((job) => job.jobId === jobId) ?? (await this.loadJob(jobId))
  }

  changeJobFilters(
    idFilter: string | null | undefined,
    jobNameFilter: string | null | undefined,
    includeInactive: boolean
  ) {
    this.resetState()

    this._state.value.remoteIdFilter = idFilter ?? null
    this._state.value.jobNameFilter = jobNameFilter ?? null
    this._state.value.includeInactiveFilter = includeInactive
  }

  async loadNextJobsPage() {
    this._state.value.loadingState = 'loading'

    const cursor = this._state.value.nextPage

    try {
      const { jobs, next } = await doWithRetry(
        () =>
          this.ctx.$fetcher.$get(
            `/api/ats-integration/jobs/${this.companyGuid}` as `/api/ats-integration/jobs/{companyGuid}`,
            {
              query: {
                cursor,
                pageSize: `${JobPageSize}`,
                filter: this._state.value.jobNameFilter,
                remoteId: this._state.value.remoteIdFilter,
                status: this._state.value.includeInactiveFilter ? undefined : ['OPEN', 'DRAFT'],
              },
            }
          ),
        {}
      )

      this._state.value.jobs = [...this._state.value.jobs, ...(jobs ?? [])]
      this._state.value.nextPage = next ?? null
      this._state.value.didLoadAllPages = next == null
      this._state.value.loadingState = 'success'
    } catch (e: any) {
      this._state.value.loadingState = 'error'
    }
  }

  async loadJob(jobId: string) {
    const { jobs } = await doWithRetry(() =>
      this.ctx.$fetcher.$get(
        `/api/ats-integration/jobs/${this.companyGuid}` as `/api/ats-integration/jobs/{companyGuid}`,
        {
          query: {
            id: jobId,
          },
        }
      )
    )
    return jobs?.[0]
  }

  resetState() {
    this._state.value = emptyState()
  }

  async startIntegrationWizard() {
    await this.runIfNotWorking(async () => {
      const { activationLink } = await this.ctx.$fetcher.$get(
        `/api/ats-integration/init/${this.companyGuid}` as `/api/ats-integration/init/{companyGuid}`
      )

      const token = await showKomboConnect(activationLink!)

      await this.ctx.$fetcher.$post(
        `/api/ats-integration/activate/${this.companyGuid}` as `/api/ats-integration/activate/{companyGuid}`,
        { token }
      )

      await this.ctx.$user.refreshData()
    }).catch(showError)
  }

  async deleteIntegration() {
    await this.runIfNotWorking(async () => {
      await this.ctx.$fetcher.$delete(
        `/api/ats-integration/disconnect/${this.companyGuid}` as `/api/ats-integration/disconnect/{companyGuid}`
      )
      await this.ctx.$user.refreshData()
    }).catch(showError)
  }

  /**
   * We don't like it when they keep clicking the button! and they just keep on clicking the dang thing!
   */
  private async runIfNotWorking(fn: () => Promise<void>) {
    if (this.working) {
      return
    }

    try {
      this.working = true
      await fn()
    } finally {
      this.working = false
    }
  }
}
