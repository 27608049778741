import { useContext } from '@nuxtjs/composition-api'
import { PostResponseContent } from '~/lib/app/apiTypes'

export default function useCompanyFileStore() {
  const ctx = useContext()
  return new CompanyFileStore(ctx)
}

class CompanyFileStore {
  constructor(private readonly ctx: ReturnType<typeof useContext>) {}

  private get $fetcher() {
    return this.ctx.$fetcher
  }

  private get $user() {
    return this.ctx.$user
  }

  uploadLogo(file: File) {
    if (!this.$user.companyId) throw new Error('not a company!')

    const formData = new FormData()
    formData.append(`file`, file)
    formData.append(`folder`, this.$user.companyId)
    return this.$fetcher.$post(`/api/files/logos`, formData)
  }
}
