import { render, staticRenderFns } from "./error.vue?vue&type=template&id=1bec7860"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorContent: require('/github/workspace/components/ErrorContent.vue').default})
