import { useWindowSize } from '@vueuse/core'
import { computed } from '@nuxtjs/composition-api'

/**
 * We use default breakpoints from tailwind - https://tailwindcss.com/docs/screens
 */
const TailwindBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

// Sometimes you can't just rely on tailwind breakpoints
export const useLayoutInfo = () => {
  const { width } = useWindowSize()

  return {
    isMobileLayout: computed(() => width.value < TailwindBreakpoints.lg),
  }
}
