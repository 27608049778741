var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('NuxtLink',{staticClass:"group flex items-center py-3 px-3 text-base leading-5 font-medium rounded-md cursor-pointer",class:[
    {
      'bg-primary-50 text-primary-600': _setup.current && !_vm.disabled,
      'text-gray-600 hover:bg-gray-50 text-sm font-medium rounded-md': !_setup.current && !_vm.disabled,
      'pointer-events-none text-gray-300': _vm.disabled,
    },
  ],attrs:{"aria-current":_setup.current ? 'page' : undefined,"aria-disabled":_vm.disabled,"disabled":_vm.disabled,"to":_setup.localeHref}},[(_vm.icon)?_c(_vm.icon,{tag:"component",staticClass:"mr-3 flex-shrink-0 size-6",class:[
      {
        'text-primary-600': _setup.current && !_vm.disabled,
        'text-gray-400 group-hover:text-gray-500': !_setup.current && !_vm.disabled,
        'text-gray-300': _vm.disabled,
      },
    ],attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"align-middle"},[_vm._v("\n    "+_vm._s(_vm.name)+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }