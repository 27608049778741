import { components } from '~/types/generated/schema'

export interface HotlistMatching {
  position: components['schemas']['JobPostShortDto']
  criteria: string[]
  groupingResults: GroupingResultCount[]
  totalMatchesCount: number
}

export interface GroupingSelectedPosition {
  positionId: string
  jobTitle: string
  isLive: boolean
}

export interface GroupingCriteria {
  name: TranslateResult
  value: string
}

export interface GroupingResultCount {
  name: TranslateResult
  count: number
}

/**
 * see HotlistMatchingSpecFieldId in Backend
 */
export enum HotlistMatchingSpecFieldId {
  ColdCalling = 'ColdCalling',
  Promotion = 'Promotion',
  Closing = 'Closing',
  B2BClosing = 'B2BClosing',
  B2CClosing = 'B2CClosing',
  AccountManagement = 'AccountManagement',
  B2BAccountManagement = 'B2BAccountManagement',
  B2CAccountManagement = 'B2CAccountManagement',
}

export const HotlistMatchingSpecFieldIdValues = Object.freeze(
  Object.values(HotlistMatchingSpecFieldId)
)

export function fieldIdToPositionKey(
  id:
    | HotlistMatchingSpecFieldId
    | components['schemas']['HotlistMatchingSpecFieldId']
): keyof components['schemas']['JobPostShortDto'] {
  const mapping = {
    ColdCalling: 'requiredColdCallingExperience',
    Promotion: 'requiredPromotionExperience',
    Closing: 'requiredSaasB2BClosingExperience',
    B2BClosing: 'requiredNonSaasB2BClosingExperience',
    B2CClosing: 'requiredNonSaasB2CClosingExperience',
    AccountManagement: 'requiredSaasB2BAccountManagementExperience',
    B2BAccountManagement: 'requiredNonSaasB2BAccountManagementExperience',
    B2CAccountManagement: 'requiredNonSaasB2CAccountManagementExperience',
  } as const

  return mapping[id as keyof typeof mapping]
}
