import { AppMessageBus, AppMessageConsumer } from '~/lib/app/appState'
import { Temporal } from '@js-temporal/polyfill'
import { UserStore } from '~/store/userStore'
import { Fetcher } from '~/lib/app/fetcher'

export function updateTalentActivityTimestampOnLoginHandler(
  fetcher: Fetcher,
  user: UserStore,
  messageBus: AppMessageBus
): AppMessageConsumer<'user-data-loaded'> {
  return async () => {
    if (!user.isTalent) return

    if (user.isImpersonating) return

    const { lastActiveOn } = await fetcher.$get(
      `/api/talent/${user.externalUserId}/activity` as `/api/talent/{talentGuid}/activity`
    )

    const now = Temporal.Now.instant()

    await fetcher.$post(
      `/api/talent/${user.externalUserId}/activity` as `/api/talent/{talentGuid}/activity`,
      { activeOn: now.toString() }
    )

    if (lastActiveOn) {
      const lastActiveDate = Temporal.Instant.from(lastActiveOn)
      const timeSinceLastActive = now.since(lastActiveDate)

      const daysOfInactivity = timeSinceLastActive.total('days')
      if (daysOfInactivity >= 30) {
        messageBus.publish('talent-reactivation-detected', { daysOfInactivity })
      }
    }
  }
}
