import { render, staticRenderFns } from "./NavTalent.vue?vue&type=template&id=5d2812f2"
import script from "./NavTalent.vue?vue&type=script&lang=ts&setup=true"
export * from "./NavTalent.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsLetterIcon: require('/github/workspace/components/IconsLetterIcon.vue').default,HotlistStatusSelectorPopupToggleButtonDesktop: require('/github/workspace/components/HotlistStatusSelectorPopupToggleButtonDesktop.vue').default,NavLink: require('/github/workspace/components/NavLink.vue').default,NavButton: require('/github/workspace/components/NavButton.vue').default})
