import { components } from '~/types/generated/schema'
import { Fetcher } from '~/lib/app/fetcher'

export type ScreeningTask = components['schemas']['RcScreeningTaskDto']

export function createScreeningTaskRepo(fetcher: Fetcher): ScreeningTaskRepo {
  return new ScreeningTaskRepoImpl(fetcher)
}

interface LoadScreeningsOptions {
  pending?: boolean
}

export interface ScreeningTaskRepo {
  loadScreenings(opts?: LoadScreeningsOptions): Promise<ScreeningTask[]>

  updateScreening(
    screeningTask: ScreeningTask,
    icp?: components['schemas']['TalentIcp'] | undefined,
    cvGrade?: number | undefined,
    seniority?: components['schemas']['TalentIcp'] | undefined,
  ): Promise<void>
}

class ScreeningTaskRepoImpl implements ScreeningTaskRepo {
  constructor(private readonly fetcher: Fetcher) {}

  async loadScreenings(opts?: LoadScreeningsOptions): Promise<ScreeningTask[]> {
    const loaded = await this.fetcher.$get(`/api/screening-tasks`, {
      query: {
        pending: String(opts?.pending),
      },
    })

    return loaded?.screenings ?? []
  }

  async updateScreening(
    screeningTask: ScreeningTask,
    icp?: components['schemas']['TalentIcp'] | undefined,
    cvGrade?: number | undefined,
    seniority?: components['schemas']['TalentSeniority'] | undefined,
  ): Promise<void> {
    await this.fetcher.$put(
      `/api/screening-tasks/${screeningTask.screeningGuid}` as `/api/screening-tasks/{screeningGuid}`,
      { outcome: screeningTask.outcome ?? undefined, icp, cvGrade, seniority },
    )
  }
}

//      icp?: components['schemas']['TalentIcp'] | null
//       /** Format: int32 */
//       cvGrade?: number | null
